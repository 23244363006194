import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    height: 250,
    marginBottom: theme.spacing(2)
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
}));

interface Props {
  pageName: string;
}

export const Header = ({ pageName }: Props) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} style={{ backgroundImage: `url(image/${pageName}/header.jpg)` }}>
      <div className={classes.overlay} />
    </Paper>
  );
};
