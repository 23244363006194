import { makeStyles, Toolbar } from '@material-ui/core';
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import logo from '../logo.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 300,
    padding: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer'
    }
  },
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'center'
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'nowrap'
    }
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    textDecoration: 'none',
    borderBottom: '2px solid transparent',
    '&:hover': {
      color: '#2A57A7 !important'
    }
  },
  active: {
    borderBottom: '2px solid #2A57A7',
    transition: 'all .35s ease-in-out',
    color: '#2A57A7 !important',
    fontWeight: 'bold'
  }
}));

const menuItems = [
  { title: 'Dak en zinkwerk', url: '/dak-en-zinkwerk' },
  { title: 'Verwarming', url: '/verwarming' },
  { title: 'Lekkage', url: '/lekkage' },
  { title: 'Legionella', url: '/legionella' },
  { title: 'Sanitair', url: '/sanitair' },
  { title: 'Contact', url: '/contact' }
];

export const Menu = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const isActive = (url: string) => location.pathname.includes(url);

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <img alt="logo" src={logo} className={classes.logo} onClick={() => history.push('/')} />
      </Toolbar>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
        {menuItems.map((item) => (
          <Link
            className={`${classes.toolbarLink} ${isActive(item.url) ? classes.active : ''}`}
            style={{ color: 'inherit' }}
            key={item.title}
            to={item.url}
          >
            {item.title}
          </Link>
        ))}
      </Toolbar>
    </React.Fragment>
  );
};
