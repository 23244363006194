import { Divider, Typography } from '@material-ui/core';
import React from 'react';

interface Props {
  title: string;
  html: string;
}

export const Content = ({ html, title }: Props) => {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      <div dangerouslySetInnerHTML={{ __html: html }}></div>
    </React.Fragment>
  );
};
