import { Button, CircularProgress, Icon, makeStyles, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '40ch',
    '& .MuiTextField-root': {
      margin: theme.spacing(1)
    }
  },
  fields: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    '& p.MuiFormHelperText-filled, p.MuiFormHelperText-root.Mui-required': {
      color: theme.palette.error.main
    }
  },
  submit: {
    padding: theme.spacing(0.75),
    '& button': {
      marginTop: theme.spacing(2)
    }
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  iconSuccess: {
    color: theme.palette.success.main,
    animation: 'bounceIn 0.75s'
  },
  iconError: {
    color: theme.palette.error.main,
    animation: 'bounceIn 0.75s'
  }
}));

const showRequired = (field?: any) => field && 'Dit is een verplicht veld';

const postForm = (form: any, captcha: string | null | undefined) => {
  return axios
    .post<{ success: boolean; message: string }>('https://us-central1-kniep-verheul.cloudfunctions.net/sendMail', {
      captcha,
      form
    })
    .then(({ data }) => data);
};

export const Contact = () => {
  const classes = useStyles();

  const { handleSubmit, register, errors } = useForm();

  const [captcha, setCaptcha] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const onSubmit = async (form: any) => {
    setLoading(true);
    const { success } = await postForm(form, captcha);
    setLoading(false);

    setSuccess(success);
    setFailed(!success);
  };

  if (success) {
    return (
      <Typography variant="h6" align="center">
        <Icon className={classes.iconSuccess}>check</Icon> Uw bericht is verzonden
      </Typography>
    );
  }

  if (failed) {
    return (
      <Typography variant="h6" align="center">
        <Icon className={classes.iconError}>clear</Icon> Uw bericht is helaas niet verzonden, probeer opnieuw
      </Typography>
    );
  }

  return (
    <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.fields}>
        <TextField
          required
          inputRef={register({ required: true, maxLength: 50 })}
          helperText={showRequired(errors.firstName)}
          name="initials"
          label="Voorletter(s)"
        />
        <TextField
          required
          inputRef={register({ required: true, minLength: 2, maxLength: 50 })}
          helperText={showRequired(errors.lastName)}
          name="lastName"
          label="Achternaam"
        />
        <TextField
          required
          inputRef={register({
            required: true,
            maxLength: 100,
            pattern: {
              value: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
              message: 'Voer een geldig e-mailadres in'
            }
          })}
          helperText={(errors.email && errors.email.message) || showRequired(errors.email)}
          name="email"
          label="E-mailadres"
        />
        <TextField
          required
          inputRef={register({ required: true, minLength: 2, maxLength: 50 })}
          helperText={showRequired(errors.phone)}
          name="phone"
          label="Telefoonnummer"
        />
        <TextField
          required
          inputRef={register({ required: true, minLength: 2, maxLength: 50 })}
          helperText={showRequired(errors.city)}
          name="city"
          label="Woonplaats"
        />
        <TextField
          required
          inputRef={register({ required: true, minLength: 2, maxLength: 1000 })}
          helperText={showRequired(errors.message)}
          name="message"
          label="Bericht"
          multiline
          rows={5}
        />
      </div>
      <div className={classes.submit}>
        {loading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <React.Fragment>
            <ReCAPTCHA
              sitekey="6LePLusUAAAAAHbjZ2ocFpnmO8Qm44BWcZvX_CXy"
              onChange={setCaptcha}
              hl="nl"
              badge="inline"
              onErrored={() => setCaptcha(null)}
            />
            <Button
              type="submit"
              disabled={!captcha || loading}
              variant="contained"
              color="primary"
              endIcon={<Icon>send</Icon>}
            >
              Verzenden
            </Button>
          </React.Fragment>
        )}
      </div>
    </form>
  );
};
