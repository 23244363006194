import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Album } from './Album';
import { Contact } from './Contact';
import { Content } from './Content';
import { Header } from './Header';

const getPath = (path: string) => path.replace('/', '');

const initialPage: PageObject = {
  title: '',
  content: '',
  images: []
};

interface PageObject {
  title: string;
  content: string;
  images: string[];
}

interface Props extends RouteComponentProps {
  isHome: boolean;
}

export const Page = ({ isHome, location }: Props) => {
  const [page, setPage] = useState(initialPage);

  const pageName = isHome ? 'home' : getPath(location.pathname);
  const isContactPage = pageName === 'contact';

  useEffect(() => {
    (async () => setPage(await import(`../pages/${pageName}`)))();
  }, [pageName]);

  return (
    <React.Fragment>
      <Header pageName={pageName} />
      <Content title={page.title} html={page.content} />
      {isContactPage ? <Contact /> : <Album pageName={pageName} images={page.images} />}
    </React.Fragment>
  );
};
