import React from 'react';
import { makeStyles, Container, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6, 0)
  }
}));

export const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="body2" color="textSecondary" align="center">
          <Link color="inherit" href="/">
            Kniep & Verheul © {new Date().getFullYear()}
          </Link>
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          Rijsenhout, regio Haarlemmermeer
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center"> 
          <Link color="inherit" href="mailto:info@kniep-verheul.nl">
            info@kniep-verheul.nl
          </Link>
        </Typography>
      </Container>
    </footer>
  );
};
