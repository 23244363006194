import { Container, CssBaseline } from '@material-ui/core';
import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Footer } from './components/Footer';
import { Menu } from './components/Menu';
import { Page } from './components/Page';

export const AppRouter = () => (
  <Router>
    <Switch>
      <Route path="*">
        <App />
      </Route>
    </Switch>
  </Router>
);

const App = () => {
  const location = useLocation();
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Menu />

        <Switch location={location}>
          <Route exact path="/" render={(props) => <Page {...props} isHome={true} />} />
          <Route exact path="/dak-en-zinkwerk" component={Page} />
          <Route exact path="/verwarming" component={Page} />
          <Route exact path="/lekkage" component={Page} />
          <Route exact path="/legionella" component={Page} />
          <Route exact path="/sanitair" component={Page} />
          <Route exact path="/contact" component={Page} />
          <Redirect to="/" />
        </Switch>

        <Footer />
      </Container>
    </React.Fragment>
  );
};
