import { makeStyles, Paper, Grid } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  paper: {
    position: 'relative',
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 350
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  }
}));

interface Props {
  pageName: string;
  images: string[];
}

export const Album = ({ pageName, images }: Props) => {
  const classes = useStyles();

  const isOdd = images.length % 2 !== 0;
  const isLast = (index: number) => images.length - 1 === index;

  return (
    <Grid className={classes.root} container spacing={1}>
      {images.map((image, index) => (
        <Grid item sm={isOdd && isLast(index) ? 12 : 6} key={index}>
          <Paper className={classes.paper} style={{ backgroundImage: `url(image/${pageName}/${image})` }}>
            <div className={classes.overlay} />
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
